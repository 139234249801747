@use "/styles/strokes.module";
@use "/styles/colors.module";
@use "/styles/typography";

.box-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.button {
  margin: 40px 0;
}

.testimonial-container {
  display: grid;
  grid-template-columns: 0.4fr 1.5fr;
}

.text-content {
  @include typography.body-2;

  padding: 8px 0;
}

.testimonial-author {
  padding: 16px 0;
}

.logo-image {
  padding: 16px 0;
}

.bottom-box {
  border-radius: 0 0 40px 40px;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 200px;
  width: 100%;

  @include strokes.solid-thin(colors.$stroke-secondary-light, "top");
}

.icon-and-text {
  align-items: center;
  display: grid;
  max-width: 90%;
}

.extra-information {
  display: none;
}

.bottom-division {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  position: relative;

  @include strokes.solid-thin(colors.$stroke-secondary-light, "right");

  &:last-child {
    border-right: none;
  }

  &:hover {
    .icon-and-text {
      display: none;
    }

    .extra-information {
      display: block;
    }
  }
}

.extra-text {
  @include typography.body-2;

  max-width: 90%;
  padding: 16px 0;
}

.icon {
  color: colors.$stroke-secondary-light;
  float: left;
  position: absolute;
  right: 8px;
}

.image-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.small-text {
  @include typography.caption-2;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding: 40px;
}
