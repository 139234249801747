@use "/styles/strokes.module";
@use "/styles/colors.module";
@use "/styles/typography";

.link-button {
  align-items: center;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: center;
  min-width: 232px;
  padding: 16px 24px;
  transition: all 150ms ease;
  width: fit-content;

  &.full-width {
    width: auto;
  }

  &.type-primary {
    &.color-blue {
      background-color: colors.$global-primary-500;
      color: colors.$neutral-high-pure;
    }

    &.color-white {
      background-color: colors.$neutral-high-pure;
      color: colors.$global-primary-500;
    }

    &:hover {
      background-color: colors.$global-primary-700;
      color: colors.$neutral-high-pure;
    }
  }

  &.type-secondary {
    &.color-blue {
      @include strokes.solid-thick(colors.$stroke-primary-light);

      color: colors.$global-primary-500;
    }

    &.color-white {
      @include strokes.solid-thick(colors.$stroke-secondary-light);

      color: colors.$neutral-high-pure;
    }

    &:hover {
      background-color: colors.$global-primary-700;
      border-color: colors.$global-primary-700;
      color: colors.$neutral-high-pure;
    }
  }

  &.size-large {
    @include typography.subtitle-2;
  }

  &.size-medium {
    @include typography.body-1(true);
  }
}
