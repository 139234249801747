@use "/styles/strokes.module";
@use "/styles/colors.module";
@use "/styles/typography";
@use "/styles/mixins";

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  text-align: start;

  @include mixins.from-lg-up {
    align-items: flex-start;
    flex-direction: row;
    gap: 12px;
    text-align: center;
  }

  .step-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 4px;

    @include mixins.from-lg-up {
      flex-direction: column;
    }

    .icon-circle {
      align-items: center;
      background-color: colors.$global-primary-500;
      border-radius: 50%;
      display: flex;
      height: 74px;
      justify-content: center;
      position: relative;
      width: 74px;

      @include strokes.solid-thick(colors.$stroke-primary-light);

      .main-icon {
        color: colors.$neutral-high-pure;
      }

      .status-icon {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 28px;
        justify-content: center;
        position: absolute;
        right: -8px;
        top: -5px;
        width: 28px;
      }

      .success {
        background-color: colors.$feedback-success-200;
        color: colors.$feedback-success-700;
      }

      .error {
        background-color: colors.$feedback-danger-200;
        color: colors.$feedback-danger-700;
      }

      .dashed-line-container {
        align-items: center;
        display: flex;
        position: absolute;
        top: 100%;

        @include mixins.from-lg-up {
          left: 100%;
          top: 50%;
        }

        .dashed-line {
          @include strokes.dashed-thin(colors.$global-primary-200);

          &::after {
            background-color: colors.$global-primary-200;
            border-radius: 50%;
            bottom: -3px;
            content: "";
            height: 8px;
            position: absolute;
            right: -3px;
            width: 8px;

            @include mixins.from-lg-up {
              top: -3px;
            }
          }
        }
      }
    }
  }

  .step-container:last-child .dashed-line-container {
    display: none;
  }

  .title-description-container {
    margin: 0 24px;
    max-width: 180px;

    @include mixins.from-lg-up {
      margin: 0 38px;
    }

    .title {
      @include typography.body-2(true);
    }

    .description {
      @include typography.caption-1;

      margin-top: 8px;
    }
  }
}
