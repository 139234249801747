@use "sass:list";

$width-hairline: 0.5px;
$width-thin: 1px;
$width-thick: 2px;
$width-heavy: 4px;
$style-solid: solid;
$style-dashed: dashed;
$style-dotted: dotted;

@mixin set-border-values($width, $style, $color, $side: "") {
  $sides: ("left", "right", "top", "bottom");

  @if list.index($sides, $side) {
    border-#{$side}: $width $style $color;
  } @else {
    border: $width $style $color;
  }
}

@mixin solid-hairline($color, $borderSide: "") {
  @include set-border-values($width-hairline, $style-solid, $color, $borderSide);
}

@mixin solid-thin($color, $borderSide: "") {
  @include set-border-values($width-thin, $style-solid, $color, $borderSide);
}

@mixin solid-thick($color, $borderSide: "") {
  @include set-border-values($width-thick, $style-solid, $color, $borderSide);
}

@mixin solid-heavy($color, $borderSide: "") {
  @include set-border-values($width-heavy, $style-solid, $color, $borderSide);
}

@mixin dashed-hairline($color, $borderSide: "") {
  @include set-border-values($width-hairline, $style-dashed, $color, $borderSide);
}

@mixin dashed-thin($color, $borderSide: "") {
  @include set-border-values($width-thin, $style-dashed, $color, $borderSide);
}

@mixin dashed-thick($color, $borderSide: "") {
  @include set-border-values($width-thick, $style-dashed, $color, $borderSide);
}

@mixin dashed-heavy($color, $borderSide: "") {
  @include set-border-values($width-heavy, $style-dashed, $color, $borderSide);
}

@mixin dotted-hairline($color, $borderSide: "") {
  @include set-border-values($width-hairline, $style-dotted, $color, $borderSide);
}

@mixin dotted-thin($color, $borderSide: "") {
  @include set-border-values($width-thin, $style-dotted, $color, $borderSide);
}

@mixin dotted-thick($color, $borderSide: "") {
  @include set-border-values($width-thick, $style-dotted, $color, $borderSide);
}

@mixin dotted-heavy($color, $borderSide: "") {
  @include set-border-values($width-heavy, $style-dotted, $color, $borderSide);
}

/* stylelint-disable max-line-length,order/properties-alphabetical-order,property-case,property-no-unknown,selector-pseudo-class-no-unknown */
:export {
  widthHairline: $width-hairline;
  widthThin: $width-thin;
  widthThick: $width-thick;
  widthHeavy: $width-heavy;
  styleSolid: $style-solid;
  styleDashed: $style-dashed;
  styleDotted: $style-dotted;
}

/* stylelint-enable */
