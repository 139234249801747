@use "/styles/strokes.module";
@use "/styles/colors.module";
@use "/styles/mixins";

.cookie-banner-container {
  background-color: colors.$neutral-high-pure;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 100;

  @include strokes.solid-thin(colors.$stroke-secondary-light, "top");
}

.cookie-banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 17px 28px 24px;
  text-align: center;
}

.policy {
  margin-bottom: 14px;
}

.link {
  color: colors.$global-primary-500;
}

@include mixins.from-lg-up {
  .cookie-banner {
    flex-direction: row;
    padding: 16px 0;
  }

  .policy {
    margin-bottom: 0;
    margin-right: 24px;
  }
}
