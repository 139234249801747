@use "/styles/strokes.module";
@use "/styles/typography";
@use "/styles/colors.module";

.mobile-menu-content-container {
  .nav-link {
    @include typography.header-4(true);
    @include strokes.solid-thin(colors.$stroke-secondary-light, "bottom");

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    width: 100%;
  }

  .toggle-link-item {
    padding: 8px;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
}
