@use "/styles/strokes.module";
@use "/styles/mixins";
@use "/styles/colors.module";
@use "/styles/typography";

.container {
  background-color: colors.$neutral-high-pure;
  border-radius: 21px 21px 0;
  display: grid;
  gap: 0.8rem;
  margin-bottom: 2.4rem;
  max-width: 345px;
  padding: 2.4rem;

  @include mixins.from-md-up {
    margin-bottom: 0;
  }
}

.title {
  @include typography.subtitle-1(true);

  color: colors.$global-primary-500;
}

.input {
  background-color: transparent;
  border-radius: 8px;
  color: colors.$text-body;
  padding: 1.8rem 1.6rem;

  @include strokes.solid-thin(colors.$global-secondary-400);
}

.terms {
  @include typography.caption-2;

  color: colors.$text-body;
}

.link {
  text-decoration: underline;
}

.button {
  margin-top: 0.8rem;
}

.button-error {
  pointer-events: none;
}

.error {
  @include typography.caption-1;

  color: colors.$feedback-danger-500;
}

.error-border {
  border-color: colors.$feedback-danger-500;
}
