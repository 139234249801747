@use "/styles/strokes.module";
@use "/styles/colors.module";
@use "/styles/typography";
@use "/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mixins.from-md-up {
    flex-direction: row;
  }
}

.title {
  @include typography.body-2(true);

  @include mixins.from-md-up {
    padding: 8px;
  }
}

.subtitle {
  @include typography.body-2;

  @include mixins.from-md-up {
    padding: 8px;
  }
}

.step-icon {
  color: colors.$neutral-high-pure;
}

.icon-container {
  align-items: center;
  background-color: colors.$global-primary-500;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  float: left;
  height: 40px;
  justify-content: center;
  margin-right: 12px;
  width: 40px;
}

.text-container {
  float: left;
  width: 80%;
}

.step {
  padding: 20px;
  position: relative;

  @include mixins.from-md-up {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &::after {
    bottom: -45px;
    content: "";
    height: 96px;
    left: 5px;
    position: absolute;

    @include strokes.solid-thin(colors.$stroke-secondary-light, "right");

    @include mixins.from-md-up {
      bottom: -12px;
      height: 410px;
      left: 190px;
      transform: rotate(90deg);
    }
  }

  &::before {
    background-color: colors.$global-primary-500;
    border-radius: 100%;
    content: " ";
    height: 5px;
    left: 1vw;
    position: absolute;
    top: 40px;
    width: 5px;
    z-index: 10;

    @include mixins.from-md-up {
      left: 48%;
      top: 0;
    }
  }

  &:nth-last-child(1) {
    &::after {
      content: none;
    }

    @include mixins.from-md-up {
      &::after {
        content: " ";
      }
    }
  }
}
